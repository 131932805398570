import img from "../assets/img/appicon.png"

const data = {
    name: "Steven LATOR",
    desc: "iOS Developer",
    social: {
        github: "https://github.com/ByTyson",
        linkdin: "https://www.linkedin.com/in/stevenltr/",
        email: "stevenlator.contact@gmail.com",
    },

    about: {
        title: "Mon parcours",
        description:
            "Passionné par le développement iOS et fervent adepte d'Apple, j'ai débuté ma carrière en tant que développeur mobile. Mon expérience préalable en tant que développeur web enrichit ma perspective et me permet de mieux appréhender ce domaine. Ma première création est disponible sur l'App Store (vous trouverez le lien plus bas sur cette page). Toujours avide de nouvelles connaissances, j'explore également d'autres langages tels que Flutter pour élargir mes compétences et me spécialiser dans le développement mobile.",
    },

    titleSkill: "Mes compétences",
    skills:
        [
            {
                skillName: "iOS",
                skillDesc: "Swift & SwiftUI",
                skillIcon: "fa-brands:swift",
            },
            {
                skillName: "Frontend",
                skillDesc: "React Angular",
                skillIcon: "whh:html",
            },
            {
                skillName: "Backend",
                skillDesc: "NestJS",
                skillIcon: "fa6-solid:server"
            },
            {
                skillName: "BDD",
                skillDesc: "MySQL / PostgreSQL / MongoDB / Firebase",
                skillIcon: "entypo:database"
            }
        ],

    titleHobby: "Mes passe-temps",
    hobbies: [
        {
            hobbyName: "Drone FPV",
            hobbyDesc: "Électronique / Pilotage / Montage vidéo",
            hobbyIcon: "healthicons:drone",
        },
        {
            hobbyName: "F1",
            hobbyDesc: "Sensation forte Compétition Stratégie",
            hobbyIcon: "fa-solid:car",


        },
        {
            hobbyName: "Moto",
            hobbyDesc: "Balade & Vitesse",
            hobbyIcon: "mdi:motorbike",
        },
        {
            hobbyName: "Running",
            hobbyDesc: "Autodiscipline Régularité Sport",
            hobbyIcon: "fa-solid:running",
        },
        {
            hobbyName: "Domotique",
            hobbyDesc: "Docker HomeAssistant Raspberry",
            hobbyIcon: "fa-solid:home",
        }
    ],
    titleProject: "Mes projets",
    projects: [
        {
            projectName: "Opaddock",
            projectDesc: "Disponible sur l'App Store",
            projectIcon: img,
            projectLangage: "Swift SwiftUI",
            projectLink: "https://apps.apple.com/fr/app/opaddock/id6478447160",
        }
    ],

    domaineName: "sltr.fr",
    domaineLink: "https://www.sltr.fr",
};

export default data;
